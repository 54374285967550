<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input v-model="searchForm.appname" placeholder="APP版本" clearable>
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-button @click="getVersion">搜索</el-button>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          @click="
            dialogVisible = true;
            fileList = [];
          "
          >新增</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-popconfirm title="确定批量删除吗？" @confirm="delHandle(null)">
          <el-button type="danger" slot="reference" :disabled="delBtlStatus"
            >批量删除</el-button
          >
        </el-popconfirm>
      </el-form-item>
    </el-form>

    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      border
      stripe
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="id" label="序号" width="70" show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="appid"
        label="APP编码"
        width="100"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="appname"
        label="APP名称"
        width="100"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="appversion"
        label="APP版本"
        width="100"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="updateIntroduce"
        label="更新内容"
        width="120"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="appsize"
        label="更新大小"
        width="100"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="downloadurl"
        label="文件地址"
        width="450"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="isneedmupdate"
        label="是否强制更新"
        width="70"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-tag
            size="small"
            v-if="scope.row.isneedmupdate === 'Y'"
            type="success"
            >是</el-tag
          >
          <el-tag
            size="small"
            v-else-if="scope.row.isneedmupdate === 'N'"
            type="danger"
            >否</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="cretated"
        label="创建时间"
        width="180"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="updated"
        label="更新时间"
        width="180"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="updateuser"
        label="更新者"
        width="100"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="note"
        label="备注"
        width="160"
        show-overflow-tooltip
      >
      </el-table-column>

      <el-table-column prop="statu" label="状态" width="70">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.statu === 1" type="success"
            >正常</el-tag
          >
          <el-tag size="small" v-else-if="scope.row.statu === 0" type="danger"
            >禁用</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="icon" label="操作" width="100">
        <template slot-scope="scope">
          <el-button type="text" @click="editHandle(scope.row.id)"
            >编辑</el-button
          >
          <el-divider direction="vertical"></el-divider>

          <template>
            <el-popconfirm
              title="确定删除吗？"
              @confirm="delHandle(scope.row.id)"
            >
              <el-button type="text" slot="reference">删除</el-button>
            </el-popconfirm>
          </template>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 20, 50, 100]"
      :current-page="current"
      :page-size="size"
      :total="total"
    >
    </el-pagination>

    <!--新增对话框-->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editForm"
        label-width="100px"
        class="demo-editForm"
      >
        <el-form-item label="序号" v-if="false" prop="id" label-width="100px">
          <el-input v-model="editForm.id"></el-input>
        </el-form-item>
        <el-form-item label="APP编码" prop="appid" label-width="100px">
          <el-input v-model="editForm.appid"></el-input>
        </el-form-item>
        <el-form-item label="APP名称" prop="appname" label-width="100px">
          <el-input v-model="editForm.appname"></el-input>
        </el-form-item>
        <el-form-item label="APP版本" prop="appversion" label-width="100px">
          <el-input v-model="editForm.appversion"></el-input>
        </el-form-item>
        <el-form-item
          label="更新内容"
          prop="updateIntroduce"
          label-width="100px"
        >
          <el-input v-model="editForm.updateIntroduce"></el-input>
        </el-form-item>
        <el-form-item label="更新大小" prop="appsize" label-width="100px">
          <el-input v-model="editForm.appsize"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="note" label-width="100px">
          <el-input v-model="editForm.note"></el-input>
        </el-form-item>
        <el-form-item
          label="是否强制更新"
          prop="isneedmupdate"
          label-width="120px"
        >
          <el-radio-group v-model="editForm.isneedmupdate">
            <el-radio label="Y">是</el-radio>
            <el-radio label="N">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="状态" prop="statu" label-width="100px">
          <el-radio-group v-model="editForm.statu">
            <el-radio :label="0">禁用</el-radio>
            <el-radio :label="1">正常</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="上传文件" label-width="100px">
          <el-upload
            class="avatar-uploader"
            action
            ref="upload"
            :before-remove="beforeRemove"
            :limit="1"
            :on-exceed="handleExceed"
            :file-list="fileList"
            :before-upload="beforeAvatarUpload"
            :http-request="handlerUpload"
          >
            <el-button size="small" icon="el-icon-document" type="primary"
              >点击上传</el-button
            >
            <div slot="tip" class="el-upload__tip">
              只能上传apk文件, 且不超过200MB
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="文件地址" prop="downloadurl" label-width="100px">
          <el-input v-model="editForm.downloadurl" disabled></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('editForm')"
            >立即创建</el-button
          >
          <el-button @click="resetForm('editForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Version",
  data() {
    return {
      searchForm: {},
      delBtlStatus: true,

      total: 0,
      size: 10,
      current: 1,

      dialogVisible: false,
      editForm: {},

      tableData: [],

      editFormRules: {
        // url: [{ required: true, message: "请输入URL", trigger: "blur" }],appid
        appid: [{ required: true, message: "请输入APP编码", trigger: "blur" }],
        appversion: [
          { required: true, message: "请输入APP版本", trigger: "blur" },
        ],
        downloadurl: [
          { required: true, message: "请上传文件", trigger: "blur" },
        ],
        isneedmupdate: [
          { required: true, message: "请选择是否强制更新", trigger: "change" },
        ],
        statu: [{ required: true, message: "请选择状态", trigger: "change" }],
      },

      multipleSelection: [],

      defaultProps: {
        children: "children",
        label: "name",
      },
      fileList: [],
      olddownloadurl: "",
    };
  },
  created() {
    this.getVersion();
  },
  methods: {
    // toggleSelection(rows) {
    //   if (rows) {
    //     rows.forEach((row) => {
    //       this.$refs.multipleTable.toggleRowSelection(row);
    //     });
    //   } else {
    //     this.$refs.multipleTable.clearSelection();
    //   }
    // },
    handleSelectionChange(val) {
      this.multipleSelection = val;

      this.delBtlStatus = val.length == 0;
    },

    handleSizeChange(val) {
      this.size = val;
      this.getVersion();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getVersion();
    },

    resetForm(formName) {
      if (
        this.olddownloadurl &&
        this.olddownloadurl != this.editForm.downloadurl
      ) {
        this.clearFile(this.editForm.downloadurl);
        this.olddownloadurl = "";
      }
      if (!this.editForm.id && this.editForm.downloadurl) {
        this.clearFile(this.editForm.downloadurl);
      }
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
      this.editForm = {};
    },
    handleClose() {
      this.resetForm("editForm");
    },

    getVersion() {
      this.$axios
        .get("/sysversion/list", {
          params: {
            name: this.searchForm.appname,
            current: this.current,
            size: this.size,
          },
        })
        .then((res) => {
          this.tableData = res.data.data.records;
          this.size = res.data.data.size;
          this.current = res.data.data.current;
          this.total = res.data.data.total;
        });
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
            .post(
              "/sysversion/" + (this.editForm.id ? "update" : "save"),
              this.editForm
            )
            .then((res) => {
              this.$message({
                showClose: true,
                message: "恭喜你，操作成功",
                type: "success",
                onClose: () => {
                  this.getVersion();
                },
              });
              if (
                this.olddownloadurl &&
                this.olddownloadurl != this.editForm.downloadurl
              ) {
                this.clearFile(this.olddownloadurl);
                this.olddownloadurl = "";
              }
              this.$refs[formName].resetFields();
              this.dialogVisible = false;
              this.editForm = {};
            });
        } else {
          //   console.log("error submit!!");
          return false;
        }
      });
    },
    editHandle(id) {
      this.$axios.get("/sysversion/info/" + id).then((res) => {
        if (res.data.data) {
          this.editForm = res.data.data;

          this.fileList = [];
          this.dialogVisible = true;
        } else {
          this.$message({
            showClose: true,
            message: "查询数据异常",
            type: "error",
            onClose: () => {
              this.getVersion();
            },
          });
        }
      });
    },
    delHandle(id) {
      var ids = [];

      if (id) {
        ids.push(id);
      } else {
        this.multipleSelection.forEach((row) => {
          ids.push(row.id);
        });
      }

      this.$axios.post("/sysversion/delete", ids).then((res) => {
        this.$message({
          showClose: true,
          message: "恭喜你，操作成功",
          type: "success",
          onClose: () => {
            this.getVersion();
          },
        });
      });
    },

    //上传文件配置
    beforeAvatarUpload(file) {
      const isType = file.type === "application/vnd.android.package-archive";
      const isLt200M = file.size / 1024 / 1024 < 200;
      if (!isType) {
        this.$message.error("上传文件只能是 apk 格式！");
      }
      if (!isLt200M) {
        this.$message.error("上传图片大小不能超过 200MB!");
      }
      return isType && isLt200M;
    },
    handleExceed(files) {
      this.$message.warning(`每次只能选择1个文件`);
    },
    beforeRemove(file) {
      if (
        file.raw.type === "application/vnd.android.package-archive" &&
        file.size / 1024 / 1024 < 200
      ) {
        return new Promise((reslove, reject) => {
          this.$confirm(`确定移除 ${file.name}?`).then(() => {
            this.$axios
              .post(
                "/fileoss/delOssFile?filename=" +
                  [
                    this.editForm.downloadurl.split("/")[
                      this.editForm.downloadurl.split("/").length - 1
                    ],
                  ],
                {}
              )
              .then((res) => {
                if (res.data.code == 200) {
                  this.editForm.downloadurl = "";
                  this.$forceUpdate();
                  reslove();
                } else {
                  reject();
                }
              })
              .catch((err) => {
                reject();
              });
          });
        });
      }
    },
    handlerUpload(param) {
      let fileObject = param.file;
      let formData = new FormData();
      formData.append("file", fileObject);
      this.$axios.post("/fileoss/uploadOssFile", formData).then((res) => {
        if (res.data.data.startsWith("OK")) {
          if (this.editForm.downloadurl) {
            this.olddownloadurl = this.editForm.downloadurl;
          }
          this.editForm.downloadurl = res.data.data.split(":")[1];
          this.$forceUpdate();
        }
      });
    },
    clearFile(downloadurl) {
      if (downloadurl) {
        this.$axios
          .post(
            "/fileoss/delOssFile?filename=" +
              downloadurl.split("/")[downloadurl.split("/").length - 1]
          )
          .then((res) => {});
      }
    },
  },
};
</script>

<style scoped>
.el-pagination {
  float: right;
  margin-top: 22px;
}
.avatar {
  width: 278px;
  height: 178px;
  display: block;
}
.el-upload {
  text-align: left;
  width: 100%;
}
</style>
